.App {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.generator {
  background-image: linear-gradient(150deg, #e28120 0%, #e8ead3ad 100%);
  border-radius: 15px;
  box-shadow: 0px 2px 10px rgba(245, 244, 244, 0.863);
  padding: 30px;
}
.generator__header {
  text-align: center;
  color: rgb(36, 0, 72);
  margin-bottom: 20px;
}
.generator__password {
  background-color: black;
  padding: 13px 15px;
  color: #fff;
  margin-bottom: 15px;
  height: 46px;
  display: flex;
  border-radius: 35px;
  justify-content: space-between;
  align-items: center;
}

.generator__password h3 {
  padding-right: 5px;
}

.copy__btn {
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 600;
}

.copy__btn:hover {
  color: black;
}

.generator__btn {
  background: black;
  border: none;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  color: #fff;
  border-radius: 30px;
  font-size: 17px;
  cursor: pointer;
  font-weight: 600;
}
.form-group {
  display: flex;
  justify-content: space-between;
  color: gre;
  margin-bottom: 15px;
}

.form-group label {
  color: black;
  font-weight: 500;
}
.fa-clipboard {
  color: greenyellow;
  padding: 8px;
  border-left: 1px solid greenyellow;
}

.fa-clipboard:hover {
  color: darkorange;
}

.pw {
  text-align: center;
}
